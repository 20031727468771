import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const FeatureV10 = () => {
  const image = useStaticQuery(graphql`
    query {
      laCasaFeatured: file(
        relativePath: { eq: "la-casa/casa1-planta-baja/casa1-12.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      segundaCasaFeatured: file(
        relativePath: { eq: "segunda-casa/casa2-13.jpeg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <section className="feature-v10">
      <div className="padding-y-xl padding-top-xxl@md padding-bottom-0@md">
        <div className="max-width-lg">
          <div className="grid">
            <Image
              className="block width-100% object-cover col-6@md"
              fluid={image.laCasaFeatured.childImageSharp.fluid}
            />

            <div className="bg-key col-6@md">
              <div className="text-component color-white v-space-md height-100% flex flex-column padding-md">
                <h2 className="font-heading font-bold">
                  <Link
                    to="/la-casa"
                    className="text-decoration-none color-white js-tab-focus"
                  >
                    La casa
                  </Link>
                </h2>
                <p className="text-sm">
                  Casa de nueva construcción, aunando las mayores comodidades
                  con la más auténtica tradición manchega en su decoración.
                </p>
                <p className="margin-top-auto">
                  <Link
                    to="/la-casa"
                    className="feature-v10__link js-tab-focus"
                  >
                    <i>Ver detalles</i>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding-y-xl padding-bottom-xxl@md padding-top-0@md">
        <div className="max-width-lg">
          <div className="grid">
            <Image
              className="block width-100% object-cover col-6@md order-2@md"
              fluid={image.segundaCasaFeatured.childImageSharp.fluid}
            />

            <div className="bg-brand-1-l-1 col-6@md order-1@md">
              <div className="text-component v-space-md height-100% flex flex-column padding-md">
                <h2 className="font-heading font-bold">
                  <Link
                    to="/segunda-casa"
                    className="text-decoration-none js-tab-focus"
                  >
                    Segunda casa
                  </Link>
                </h2>
                <p className="text-sm">Tenemos una segunda casa disponible.</p>
                <p className="margin-top-auto">
                  <Link
                    to="/segunda-casa"
                    className="feature-v10__link js-tab-focus"
                  >
                    <i>Ver detalles</i>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureV10
