import React from "react"
import { withPrefix } from "gatsby"

import Icon from "../components/Icon/Icon"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import FeatureV4 from "../components/FeatureV4/FeatureV4"
import FeatureV10 from "../components/FeatureV10/FeatureV10"

class IndexPage extends React.Component {
  componentDidMount() {
    let revealEffects = document.createElement("script")
    revealEffects.src = `${withPrefix("scripts/reveal-effects-script.js")}`
    revealEffects.id = "reveal-effects-script-js"
    document.body.appendChild(revealEffects)
  }

  componentWillUnmount() {
    document.getElementById("reveal-effects-script-js").remove()
  }

  render() {
    return (
      <Layout>
        <SEO />
        <div className="container">
          <div className="margin-y-xxl">
            <FeatureV4 />
          </div>
          <div className="margin-top-xxl margin-bottom-md">
            <FeatureV10 />
          </div>
          <div className="inline-block margin-x-auto max-width-xs bg-key text-component padding-component">
            <a
              href="http://www.rinconesdelmundo.com/casasrurales/calendario/cal.php?nr_casa=3023"
              target="_blank"
              rel="noreferrer"
              className="margin-top-sm inline-flex items-center text-decoration-none text-sm js-tab-focus"
            >
              <Icon iconName="icon-webapp-calendar" iconClassName="icon--md" />
              <span className="margin-left-xs color-white">
                Ver fechas libres
              </span>
            </a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
