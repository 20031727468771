import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const FeatureV4 = () => {
  const image = useStaticQuery(graphql`
    query {
      homeFeatured: file(relativePath: { eq: "home/home-portada.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <section className="feature-v4 feature-v4--invert">
      <div className="_container max-width-lg">
        <div className="feature-v4__grid grid gap-xl items-center">
          <div className="col-6@md position-relative z-index-1">
            {/* <div className="text-sm color-contrast-medium margin-bottom-xxs">This is a Label</div> */}

            <div className="text-component">
              <h1 className="text-xxl font-heading font-bold reveal-fx reveal-fx--translate-up">
                Te invitamos a conocer nuestras casas rurales
              </h1>
              <p
                className="text-sm reveal-fx reveal-fx--translate-up"
                data-reveal-fx-delay="100"
              >
                En Argamasilla de Alba, lugar donde Cervantes dio comienzo a su
                universal obra, le ofrecemos un hogar donde alojarse con todo
                lujo de detalles y toda la tradición manchega, mientras disfruta
                del entorno natural y el patrimonio histórico del que disfruta
                nuestra población.
              </p>
            </div>
          </div>

          <div className="col-6@md opacity-90%">
            <Image
              className="block width-100%"
              fluid={image.homeFeatured.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureV4
